import { graphql } from 'gatsby';
import React from 'react';
import Header from '../../components/HeaderDynamicPages/Header';
import Layout from '../../components/Layout/Layout';
import {
    BioWrapper,
    Container,
    Title,
    PersonWrapper,
    FieldName,
    DataBox,
    DataFieldText,
    PersonImage,
    DataFieldLink,
    FieldPosition,
} from './personStyle';
import Alternate from '../../components/Seo/Alternate';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { urlForImage } from '../../utils/urlForImage';
import placeholder from '../../static/images/placeholder.jpg';
const Person = ({
    data: {
        drupal: { person: person },
    },
    pageContext: { languages: languages, noIndex: noIndex },
}) => {
    const { t } = useTranslation();

    return (
        <Layout
            title={person?.metatag?.field_metatag_title}
            description={person?.metatag?.field_metatag_description}
            languageVersionsUrl={languages}
            noIndex={noIndex || person?.metatag?.field_metatag_no_index}
        >
            <Alternate languages={languages} />
            <Header category={t('home')} item={person?.title} />
            <Container>
                <Title>{person?.title}</Title>
                <BioWrapper dangerouslySetInnerHTML={{ __html: person?.field_bio }}></BioWrapper>
                <PersonWrapper>
                    <DataBox>
                        <PersonImage
                            src={
                                person?.field_image?.length > 0
                                    ? urlForImage(person.field_image[0]?.field_media_image)
                                    : placeholder
                            }
                            alt={
                                person?.field_image?.length > 0 && person.field_image[0]?.alt
                                    ? person.field_image[0].alt
                                    : 'person img'
                            }
                        />
                    </DataBox>
                    <DataBox>
                        {person?.title && <FieldName>{person.title}</FieldName>}
                        {person?.field_position && (
                            <FieldPosition
                                dangerouslySetInnerHTML={{
                                    __html: person.field_position,
                                }}
                            />
                        )}
                    </DataBox>
                    <DataBox>
                        {person?.field_phone && (
                            <DataFieldText>
                                {t('phone')}:{' '}
                                <DataFieldLink href={`tel:${person.field_phone}`}>{person.field_phone}</DataFieldLink>
                            </DataFieldText>
                        )}
                        {person?.field_mobile && (
                            <DataFieldText>
                                {t('mobile')}:{' '}
                                <DataFieldLink href={`tel:${person.field_mobile}`}>{person.field_mobile}</DataFieldLink>
                            </DataFieldText>
                        )}
                    </DataBox>
                    <DataBox>
                        {person?.field_email && (
                            <DataFieldText>
                                Email:{' '}
                                <DataFieldLink href={`mailto:${person.field_email}`}>
                                    {person.field_email}
                                </DataFieldLink>
                            </DataFieldText>
                        )}
                    </DataBox>
                </PersonWrapper>
            </Container>
        </Layout>
    );
};

export default Person;

export const query = graphql`
    query person($id: Int!, $language: String!) {
        drupal {
            person(id: $id, language: $language) {
                title
                field_image {
                    field_media_image
                    alt
                }
                field_bio
                field_email
                field_phone
                field_mobile
                field_position
                metatag {
                    field_metatag_description
                    field_metatag_title
                    field_metatag_no_index
                }
            }
        }
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
